import { Stack, Typography } from "@mui/material";
import Logo, { LogoProps } from "@cc/logo";
import {
  MotionStack,
  MotionTypography,
  MotionVariantProps,
} from "@cc/motion-components";
import { MailRounded, PhoneRounded } from "@mui/icons-material";

import { MaterialImage } from "@/commonComponents/material-components";
import Link from "@cc/link";
import { deepmerge } from "@mui/utils";

export interface SocialLinksProps {
  emailId: string;
  phoneNo: string;
  socialLinks: {
    icon: string;
    url: string;
  }[];
  LogoProps?: LogoProps;
}

const SocialLinks = (props: SocialLinksProps) => {
  const { emailId, phoneNo, socialLinks, LogoProps } = props;
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}
      rowGap={3}
      columnGap={3}
    >
      <Logo
        {...deepmerge(defaultLogoProps, LogoProps)}
      />
      <MotionStack
        direction={{
          xs: "column",
          sm: "row",
        }}
        columnGap={3}
        rowGap={2}
        justifyContent={"space-between"}
        width={{ sm: "fit-content", md: 0.4 }}
        minWidth={"fit-content"}
        variants={footerChildrenMotionVariants}
      >
        <MotionTypography
          display={"flex"}
          alignItems={"center"}
          variant={"subtitle1"}
          columnGap={1}
          whiteSpace={"nowrap"}
        >
          {emailId && <><MailRounded fontSize={"small"} /> {emailId} </> }
        </MotionTypography>
        <MotionTypography
          display={"flex"}
          alignItems={"center"}
          variant={"subtitle1"}
          columnGap={1}
          whiteSpace={"nowrap"}
        >
          {phoneNo && <><PhoneRounded fontSize={"small"} /> {phoneNo} </>}
        </MotionTypography>
      </MotionStack>
      <MotionStack
        variants={footerChildrenMotionVariants}
        sx={{
          width: { xs: 1, lg: "fit-content" },
          alignItems: "center",
          rowGap: 2,
        }}
      >
        <Typography
          display={{
            xs: "block",
            lg: "none",
          }}
        >
          {/* TODO: Intergation - Follow us on  */}
          Follow us on
        </Typography>
        <Stack direction={"row"} spacing={3}>
          {socialLinks.map((item, index) => {
            const { url, icon } = item;
            return (
              <Link href={url} key={index}>
                <MaterialImage
                  src={icon}
                  alt={"img"}
                  height={40}
                  width={40}
                  sx={{
                    height: { xs: 32, md: 37, xxl: 40 },
                    width: { xs: 32, md: 37, xxl: 40 },
                  }}
                />
              </Link>
            );
          })}
        </Stack>
      </MotionStack>
    </Stack>
  );
};

export default SocialLinks;

export const footerChildrenMotionVariants: MotionVariantProps = {
  initial: {
    opacity: 0,
    x: "80%",
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.6,
    },
  },
};


const defaultLogoProps: LogoProps = {
  variants: footerChildrenMotionVariants,
  ImageProps: {
    src: "",
    sx: {
      width: { xs: 107, md: 136, xxl: 166 },
      height: { xs: 64, md: 81, xxl: 99 },
    },
  },
};