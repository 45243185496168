"use client";
import SectionWrapper from "@cc/section-wrapper";
import SocialLinks, { SocialLinksProps } from "@/layout/footer/social-links";
import CommonLinks, {
  FooterCommonLinksProps,
} from "@/layout/footer/common-links";
import FooterCopyright, {
  FooterCopyrightProps,
} from "@/layout/footer/footer-copyright";
import { alpha, Divider, SxProps, Theme } from "@mui/material";
import { LogoProps } from "@/commonComponents/logo";

export interface BaseFooterProps {
  emailId: string;
  phoneNo: string;
  socialLinks: SocialLinksProps["socialLinks"];
  commonLinks: FooterCommonLinksProps["data"];
  copyrightText: FooterCopyrightProps["copyrightText"];
  footerMenu: FooterCopyrightProps["footerMenu"];
  LogoProps: LogoProps;
}

const BaseFooter = (props: BaseFooterProps) => {
  const {
    emailId,
    phoneNo,
    socialLinks,
    commonLinks,
    copyrightText,
    footerMenu,
    LogoProps,
  } = props;

  return (
    <SectionWrapper
      color={"secondary.main"}
      showSectionBgTransactionProps={{
        bottom: 0,
        top: "unset",
      }}
      SectionProps={{
        component: "footer",
        sx: {
          bgcolor: "primary.900",
        },
      }}
      rowGap={{ xs: 0, sm: 2, lg: 9 }}
      divider={<Divider sx={dividerSx} />}
      initial={"initial"}
      whileInView={"animate"}
      viewport={{
        once: true,
        amount: 0.5,
      }}
      transition={{
        staggerChildren: 0.2,
      }}
    >
      <SocialLinks
        emailId={emailId}
        phoneNo={phoneNo}
        socialLinks={socialLinks}
        LogoProps={LogoProps}
      />
      <CommonLinks data={commonLinks} />
      <FooterCopyright copyrightText={copyrightText} footerMenu={footerMenu} />
    </SectionWrapper>
  );
};

export default BaseFooter;

const dividerSx: SxProps<Theme> = (theme) => {
  return {
    display: { xs: "block", lg: "none" },
    borderColor: alpha(theme.palette.common.white, 0.2),
    mt: "32px !important",
    mb: "20px !important",
  };
};
