"use client";
import { Stack, Theme, useMediaQuery } from "@mui/material";
import { BaseHeaderProps } from "@/layout/header";
import NavigationDrawer from "@/layout/header/navigation/navigation-drawer";
import { useState } from "react";
import SearchButton from "@/layout/header/navigation/search-drawer";
import LanguageToggleButton from "@/layout/header/navigation/language-toggle-button";
import { useApp } from "@/hooks/app-config";

export interface NavigationActionsProps {
  routes: BaseHeaderProps["routes"];
}

const NavigationActions = (props: NavigationActionsProps) => {
  const { routes } = props;
  const { isSearch, isMultiLanguage } = useApp();
  const [isNavDrawer, setNavDrawer] = useState(false);
  const isDownLgXl = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("lg_xl"),
  );
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={{
        xs: 3,
        sm: 2,
      }}
      height={"inherit"}
    >
      {isSearch && (
        <SearchButton
          sx={[
            {
              display: {
                xs: isNavDrawer ? "none" : "flex",
                sm: "flex",
              },
            },
          ]}
        />
      )}
      {isMultiLanguage && (
        <LanguageToggleButton
          sx={{
            display: {
              xs: isNavDrawer ? "flex" : "none",
              sm: "flex",
            },
          }}
        />
      )}

      {isDownLgXl && (
        <NavigationDrawer
          routes={routes}
          sx={[
            {
              display: {
                xs: "flex",
                lg_xl: "none",
              },
            },
          ]}
          onToggle={setNavDrawer}
        />
      )}
    </Stack>
  );
};

export default NavigationActions;
