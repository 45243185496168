"use client";
import { Box, BoxProps } from "@mui/material";
import { sxArrayUtil } from "@util/sx-helpers";
import { MotionLink, MotionLinkProps } from "@cc/motion-components";
import { MaterialImage, MaterialImageProps } from "@cc/material-components";
import { SetOptional } from "type-fest";

export interface LogoProps extends Partial<MotionLinkProps> {
  SvgIconProps?: Omit<BoxProps<"svg">, "children">;
  ImageProps?: SetOptional<MaterialImageProps, "alt">;
}

const Logo = (props: LogoProps) => {
  const {
    SvgIconProps = {},
    ImageProps = {} as MaterialImageProps,
    ...restLogoProps
  } = props;
  const { sx: sxImageProps, ...restImageProps } = ImageProps;
  const { sx: sxSvgIconProps, ...restSvgIconProps } = SvgIconProps;

  return (
    <MotionLink href={"/"} disableRipple variant={"text"} {...restLogoProps}>
      {ImageProps && ImageProps.src ? (
        <MaterialImage
          alt={"logo"}
          width={107}
          height={64}
          sx={[
            {
              width: 107,
              height: 64,
            },
            ...sxArrayUtil(sxImageProps),
          ]}
          {...restImageProps}
        />
      ) : (
        <Box
          component={"svg"}
          sx={[
            {
              width: 107,
              height: 64,
              fill: "none",
            },
            ...sxArrayUtil(sxSvgIconProps),
          ]}
          viewBox="0 0 107 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...restSvgIconProps}
        >
          <g clipPath="url(#clip0_1301_15)">
            <path d="M0.5 63.5V0.5H63.4297V63.5H0.5Z" stroke="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.08557 36.2798V27.7197H11.4892C12.9946 27.7197 15.4829 27.936 15.4829 29.9447C15.4829 30.9645 14.6228 31.6444 13.6397 31.9225C14.8685 32.3243 15.4829 33.0041 15.4829 33.9621C15.4829 36.0635 13.1482 36.2489 11.4892 36.2798H4.08557ZM6.78899 32.6951L7.58772 31.3663C7.74133 31.0881 8.10998 30.8718 8.4479 30.8718H9.95321H11.4585C12.0115 30.8718 12.4416 30.6246 12.4416 30.192C12.4416 29.7593 12.0115 29.5121 11.4585 29.5121H6.78899V32.6951ZM6.78899 32.6951V36.2798L7.58772 34.951C7.74133 34.6729 8.10998 34.4875 8.4479 34.4875H11.4278C11.9808 34.4875 12.4416 34.0857 12.4416 33.5913C12.4416 33.0968 12.0115 32.6951 11.4278 32.6951H6.78899ZM22.0264 29.7902L18.3707 36.2798H15.1757L20.2754 27.7197H22.0264H23.7775L28.8771 36.2798H25.6822L24.822 34.7656H20.091L20.8898 33.4368C21.0434 33.1586 21.4427 32.9423 21.7807 32.9423H23.8389L22.0264 29.7902ZM44.2374 29.7902L40.551 36.2798H37.356L42.4557 27.7506H44.2067H45.9578L51.0574 36.2798H47.8625L47.0023 34.7656H42.2713L43.0393 33.4368C43.193 33.1586 43.5923 32.9423 43.9302 32.9423H45.9885L44.2374 29.7902ZM33.7924 34.4875C34.4068 34.4875 34.8984 34.1784 34.8984 33.5604V27.7197H37.6325V33.8076C37.6325 35.6618 36.4344 36.2798 34.8677 36.2798H30.3824C30.0445 36.2798 29.6759 36.0635 29.4915 35.7854L28.7235 34.4875H33.7924ZM56.0035 34.4875C56.6179 34.4875 57.1094 34.1784 57.1094 33.5604V27.7197H59.8435V33.8076C59.8435 35.6618 58.6454 36.2798 57.0787 36.2798H52.5627C52.2248 36.2798 51.8254 36.0635 51.6718 35.7854L50.8731 34.4875H56.0035Z"
              fill="white"
            />
            <path
              d="M72.1014 25.4023H69.183V38.5979H71.9786C73.7296 38.5979 74.6205 37.6708 74.6205 35.8166V33.8388C74.6205 32.9736 74.2826 32.3555 73.576 32.0156C74.2519 31.6447 74.6205 31.0576 74.6205 30.1923V28.2145C74.6205 26.3294 73.7911 25.4023 72.1014 25.4023ZM72.7466 35.8166C72.7466 36.4038 72.4701 36.7128 71.9171 36.7128H71.0877V32.9427H71.6714C71.9478 32.9427 72.1014 32.9736 72.2243 33.0045C72.5623 33.1281 72.7466 33.4062 72.7466 33.8388V35.8166ZM72.7466 30.1614C72.7466 30.594 72.5623 30.8722 72.2243 30.9958C72.1322 31.0267 71.9478 31.0576 71.6714 31.0576H71.0877V27.2874H71.9171C72.4701 27.2874 72.7466 27.5965 72.7466 28.1836V30.1614Z"
              fill="white"
            />
            <path
              d="M78.3992 25.186C76.5867 25.186 75.6958 26.1131 75.6958 27.9673V36.033C75.6958 37.8871 76.5867 38.8142 78.3992 38.8142C80.2117 38.8142 81.1334 37.8871 81.1334 36.033V34.2715H79.2287V36.033C79.2287 36.6201 78.9522 36.9292 78.3992 36.9292C77.8462 36.9292 77.5698 36.6201 77.5698 36.033V32.9427H81.1026V27.9673C81.1334 26.1131 80.2117 25.186 78.3992 25.186ZM79.2287 31.0576H77.5698V27.9673C77.5698 27.3801 77.8462 27.0711 78.3992 27.0711C78.9522 27.0711 79.2287 27.3801 79.2287 27.9673V31.0576Z"
              fill="white"
            />
            <path
              d="M90.7487 25.186C88.9362 25.186 88.0453 26.1131 88.0453 27.9673V36.033C88.0453 37.8871 88.9362 38.8142 90.7487 38.8142C92.5612 38.8142 93.4828 37.8871 93.4828 36.033V27.9673C93.4521 26.1131 92.5612 25.186 90.7487 25.186ZM91.5782 36.033C91.5782 36.6201 91.3017 36.9292 90.7487 36.9292C90.1957 36.9292 89.9192 36.6201 89.9192 36.033V27.9673C89.9192 27.3801 90.1957 27.0711 90.7487 27.0711C91.3017 27.0711 91.5782 27.3801 91.5782 27.9673V36.033Z"
              fill="white"
            />
            <path
              d="M104.481 25.4023H101.562V38.5979H104.358C106.109 38.5979 107 37.6708 107 35.8166V28.1836C107 26.3294 106.14 25.4023 104.481 25.4023ZM105.095 35.8166C105.095 36.4038 104.819 36.7128 104.266 36.7128H103.436V27.2874H104.266C104.819 27.2874 105.095 27.5965 105.095 28.1836V35.8166Z"
              fill="white"
            />
            <path
              d="M88.1067 25.4023H86.1099L85.4033 28.0909L86.54 30.5631L88.1067 25.4023Z"
              fill="white"
            />
            <path
              d="M97.63 25.124C96.862 25.124 96.2169 25.464 95.7254 26.1438C95.2031 26.7928 94.9266 27.6272 94.9266 28.5852V38.8758H96.7392V28.5543C96.7392 28.2143 96.8313 27.9362 96.9849 27.7199C97.1692 27.5035 97.3843 27.3799 97.63 27.3799C97.8758 27.3799 98.0909 27.5035 98.2752 27.7199C98.4595 27.9362 98.521 28.2143 98.521 28.5543V38.8449H100.333V28.5543C100.333 27.5963 100.057 26.7928 99.5347 26.1129C98.9818 25.4331 98.3673 25.124 97.63 25.124Z"
              fill="white"
            />
            <path
              d="M86.079 32.0156L84.2972 31.9847L81.2866 25.526L83.0991 25.4951L86.079 32.0156Z"
              fill="#EE7623"
            />
            <path
              d="M83.0377 38.5667L86.079 32.0153L84.2972 31.9844L81.2866 38.5049L83.0377 38.5667Z"
              fill="#1D8649"
            />
          </g>
          <defs>
            <clipPath id="clip0_1301_15">
              <rect width="107" height="64" fill="white" />
            </clipPath>
          </defs>
        </Box>
      )}
    </MotionLink>
  );
};

export default Logo;
