"use client";
import { sxArrayUtil } from "@util/sx-helpers";
import { CloseRounded, SearchRounded } from "@mui/icons-material";
import { alpha, Drawer, IconButtonProps } from "@mui/material";
import { appbarHeight } from "@/layout/header/reactive-appbar";
import BorderedIconButton from "@cc/bordered-icon-button";
import { usePathname, useRouter } from "next/navigation";
import { useAppSearchParams, useSearch } from "@/hooks/app-config";
import { useCallback } from "react";
import { useEffectOnce } from "react-use";
import dynamic from "next/dynamic";

// Dynamic import
const SearchBar = dynamic(
  () => import("@/layout/header/navigation/search-bar"),
  {
    ssr: false,
  },
);
const SuggestionBar = dynamic(
  () => import("@/layout/header/navigation/suggestionBar"),
  {
    ssr: false,
  },
);

export interface SearchDrawerTogglerProps
  extends Omit<IconButtonProps, "children"> {}

const SearchButton = (props: SearchDrawerTogglerProps) => {
  return (
    <>
      <SearchDrawerToggler {...props} />
      <SearchDrawer />
    </>
  );
};

export default SearchButton;

const SearchDrawer = () => {
  const { keyword, isDrawerOpen, toggleDrawer } = useSearch();

  return (
    <Drawer
      open={isDrawerOpen}
      variant={"temporary"}
      onClose={() => {
        toggleDrawer(false);
      }}
      anchor={"top"}
      PaperProps={{
        sx: (theme) => ({
          mt: appbarHeight,
          height: keyword.length
            ? {
                xs: `calc(100vh - ${appbarHeight.xs})`,
                md: `calc(100vh - ${appbarHeight.md})`,
                xxl: `calc(100vh - ${appbarHeight.xxl})`,
              }
            : "min-content",
          background: alpha(theme.palette.common.white, 0.96),
        }),
      }}
    >
      {isDrawerOpen && (
        <>
          <SearchBar />
          <SuggestionBar />
        </>
      )}
    </Drawer>
  );
};

const SearchDrawerToggler = (props: SearchDrawerTogglerProps) => {
  const { sx, ...restIconButtonProps } = props;
  const { isDrawerOpen, setKeyword, toggleDrawer } = useSearch();
  const keyword = useAppSearchParams("search");
  const pathname = usePathname();
  const router = useRouter();

  useEffectOnce(() => {
    if (!isDrawerOpen) {
      setKeyword(keyword);
      toggleDrawer(!!keyword);
    }
  });

  const handleToggleDrawer = useCallback(() => {
    if (isDrawerOpen && keyword.length > 0) {
      router.replace(pathname);
    }
    toggleDrawer(!isDrawerOpen);
  }, [isDrawerOpen, keyword.length, pathname, router, toggleDrawer]);

  return (
    <BorderedIconButton
      sx={[
        isDrawerOpen && {
          "&::before": {
            display: "block",
          },
        },
        ...sxArrayUtil(sx),
      ]}
      onClick={handleToggleDrawer}
      color={"secondary"}
      size={"small"}
      {...restIconButtonProps}
    >
      {isDrawerOpen ? <CloseRounded /> : <SearchRounded />}
    </BorderedIconButton>
  );
};
