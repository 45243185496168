"use client";
import { alpha, Typography } from "@mui/material";
import { sxArrayUtil } from "@util/sx-helpers";
import MLink, { MLinkProps } from "@cc/m-link";
import { useParams, usePathname } from "next/navigation";
import { RouteParams } from "@/app/[locale]/route.type";
import { map, values } from "lodash";
import { useMemo } from "react";
import { localeCookieName } from "@/i18nConfig.client";

const languages: {
  [key in any]: {
    identifier: key;
    value: string;
  };
} = {
  en: {
    identifier: "en",
    value: "En",
  },
  hi: {
    identifier: "hi",
    value: "हि",
  },
};

interface LanguageToggleButtonProps extends Omit<MLinkProps, "children"> {}

const LanguageToggleButton = (props: LanguageToggleButtonProps) => {
  const { sx, ...restButtonProps } = props;
  const { locale } = useParams<RouteParams>();
  const pathname = usePathname();

  const isEnglish = useMemo(() => {
    return locale === languages.en.identifier;
  }, [locale]);

  const isHindi = useMemo(() => {
    return locale === languages.hi.identifier;
  }, [locale]);

  const langHref = useMemo(() => {
    if (isHindi) {
      const tempPathname = pathname.replace(`/${locale}`, "");
      return `${tempPathname === "" ? "/" : tempPathname}/?${localeCookieName}=${languages.en.identifier}`;
    }
    return `/${languages.hi.identifier}${pathname}/?${localeCookieName}=${languages.hi.identifier}`;
  }, [isHindi, locale, pathname]);

  return (
    <MLink
      href={`${langHref}`}
      disableLocale
      prefetch={false}
      sx={[
        (theme) => ({
          display: "flex",
          alignItems: "center",
          height: "inherit",
          columnGap: 1.25,
          borderRadius: 0,
          position: "relative",
          color: "inherit",
          border: "1px solid",
          borderColor: alpha(theme.palette.primary.contrastText, 0.3),
          py: 0,
          pt: 0.25,
          px: 1.25,
          "&::before": {
            content: '""',
            position: "absolute",
            mt: -0.25,
            width: isEnglish ? 0.4 : 0.35,
            height: 0.8,
            transform: `translateX(${isEnglish ? -50 : 64}%)`,
            background: alpha(theme.palette.primary.contrastText, 0.4),
            transition: "transform 0.2s ease-in-out",
          },
        }),
        ...sxArrayUtil(sx),
      ]}
      {...restButtonProps}
    >
      {map(values(languages), (item, index) => {
        const { value } = item;
        return (
          <Typography
            key={`${item}-${index}`}
            variant={"caption"}
            color={"inherit"}
          >
            {value}
          </Typography>
        );
      })}
    </MLink>
  );
};

export default LanguageToggleButton;
