"use client";
import {
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Divider,
  List,
  ListItem,
  Theme,
  useMediaQuery,
} from "@mui/material";
import MLink, { MLinkProps } from "@cc/m-link";
import { Overline, Subtitle1 } from "@theme/components/typography.fontvariant";
import { useState } from "react";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import {
  MotionAccordion,
  MotionStack,
  MotionVariantProps,
} from "@cc/motion-components";

export interface FooterCommonLinksProps {
  data: {
    title: string;
    links: {
      name: string;
      url: MLinkProps["href"];
    }[];
  }[];
}

const CommonLinks = (props: FooterCommonLinksProps) => {
  const { data } = props;

  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  const [expanded, setExpanded] = useState<string | false>(false);

  return (
    <MotionStack
      direction={{ xs: "column", sm: "row" }}
      justifyContent={{ sx: "flex-start", lg: "space-between" }}
      alignItems={"flex-start"}
      flexWrap={"wrap"}
      columnGap={{
        xs: 0,
        sm: 15,
        lg: 0,
      }}
    >
      {data.map((item, index) => {
        const { title, links } = item;
        return (
          <MotionAccordion
            key={`${title}-${index}`}
            expanded={isSm || expanded === title}
            disableGutters
            square
            elevation={0}
            onChange={() => {
              setExpanded((prev) => (prev === title ? false : title));
            }}
            sx={(theme) => ({
              width: { xs: 1, sm: 0.3, md: 0.3, lg: "fit-content" },
              minWidth: { xs: "unset", sm: 200, md: 250, lg: 200, xxl: 240 },
              maxWidth: { xs: "unset", sm: 200, md: 250, lg: 220, xxl: 250 },
              background: "transparent",
              flexGrow: { xs: 1, lg: 0 },
              flexShrink: { xs: 1, lg: 0 },
              [theme.breakpoints.down("sm")]: {
                "&:before": {
                  content: "none",
                },
              },
            })}
            variants={footerChildrenMotionVariants}
          >
            <AccordionSummary
              sx={{
                fontSize: Overline,
                fontWeight: 700,
                px: 0,
                [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
                  color: "inherit",
                },
              }}
              expandIcon={
                isSm ? undefined : expanded === title ? (
                  <RemoveRounded />
                ) : (
                  <AddRounded />
                )
              }
            >
              {title}
            </AccordionSummary>
            <Divider
              sx={{
                display: { xs: "none", sm: "block" },
                mb: 0.5,
                mt: 1.5,
              }}
            />
            <AccordionDetails
              sx={{
                px: 0,
                pt: {
                  xs: 0,
                  sm: 1,
                },
              }}
            >
              <List disablePadding>
                {links.map((link, index) => {
                  const { name, url } = link;
                  if (!name || !url) return null;
                  return (
                    <ListItem
                      key={`${name}-${index}`}
                      disableGutters
                      sx={{
                        py: { xs: 1, sm: 1.5 },
                      }}
                    >
                      <MLink
                        href={url}
                        variant={"text"}
                        disableRipple
                        sx={{
                          fontSize: Subtitle1,
                          color: "inherit",
                        }}
                        onClick={() => {
                          setTimeout(() => {
                            setExpanded(false);
                          }, 1000);
                        }}
                      >
                        {name}
                      </MLink>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </MotionAccordion>
        );
      })}
    </MotionStack>
  );
};

export default CommonLinks;

export const footerChildrenMotionVariants: MotionVariantProps = {
  initial: {
    opacity: 0,
    y: "80%",
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
    },
  },
};
