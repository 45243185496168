import {Components, Theme} from "@mui/material";

/**
 * @TabsVariants
 * Here we can define the variants, defaultProps and styleOverrides
 *
 * The variants are used to override the default styles of the Button component
 *
 * @see https://mui.com/material-ui/react-tabs/#customization
 */
export const TabsVariants: Components<Omit<Theme, "components">>["MuiTabs"] =
  {};
