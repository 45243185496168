"use client";
import Logo, { LogoProps } from "@cc/logo";
import SectionWrapper from "@cc/section-wrapper";
import Navigation from "@/layout/header/navigation";
import { MLinkProps } from "@cc/m-link";
import ReactiveAppBar from "@/layout/header/reactive-appbar";
import { deepmerge } from "@mui/utils";

export interface BaseHeaderProps {
  routes: {
    title: string;
    href?: MLinkProps["href"];
    children?: BaseHeaderProps["routes"][number][];
  }[];
  LogoProps?: LogoProps;
}

const BaseHeader = (props: BaseHeaderProps) => {
  const { routes, LogoProps } = props;
  return (
    <SectionWrapper
      direction={"row"}
      alignItems={"center"}
      py={0}
      height={"inherit"}
      SectionProps={{
        component: ReactiveAppBar,
      }}
      color={"secondary.main"}
    >
      <Logo
        {...deepmerge(defaultLogoProps, LogoProps)}
      />
      <Navigation
        sx={{
          ml: "auto",
        }}
        routes={routes}
      />
    </SectionWrapper>
  );
};

export default BaseHeader;

const defaultLogoProps: LogoProps = {
  ImageProps: {
    src:"",
    sx: {
      width: { xs: 71, md: 91, xxl: 107 },
      height: { xs: 40, md: 52, xxl: 64 },
      objectFit: "contain",
    },
  },
};
